.header {
    background-color: black;
    width: 100%;
    height: 3rem;
}

.header nav {
    display: flex;
    height: 100%;
    justify-content: flex-start;
}

.header div {
    float: left;
}

.header ul {
    height: 100%;
    list-style: none;
    display: flex;
    align-items: center;
    padding-left: 0;
}

.header li {
    margin: 0 1rem;
    width: 5rem;
}

.header button {
    display: none;
}

.header a.active,
.header a:active {
    color: #6ccba2;
}
.header a {
    color: white;
    text-decoration: none;
}

.header a:hover {
    color:  #6ccba2;
    text-decoration: underline;
    text-decoration-color: #6ccba2;
}

.logo {
    height:3rem;
    width: auto;
    margin-left: 0.5rem;
}

.contacts {
    margin-left: auto;
    margin-right: 0.5rem;
}

.img {
    height: 3rem;
    padding-inline: .1rem;
}

.resume {
    color: #6CCBA2 !important; 
    border-bottom: 2px solid #6CCBA2;
    padding: 2px;
    padding-block: 5px;
    margin-left: .2rem;
}

@media screen and (max-width:760px) {
    .header ul {
        display: none;
    }

    .header button {
        display: flex;
    }
}

@media screen and (max-width:500px) {
    .img {
        margin-top: .8rem;
        width: 2rem;
        height: auto;
    }

    .resume {
        display: none;
    }
}

@media screen and (max-width:300px) {
    .img {
        display: none;
    }
}
