.toggle {
    background-color: black;
    border: 0px;
    height: 3rem;
}

.toggle:hover {
    background-color: #6CCBA2;
}

.toggle:focus {
    background-color: rgb(49, 49, 49);
    box-shadow: none;
}

.toggle::after {
    display: none; 
}

.img {
    height:100%;
    width:auto;
}

.menu {
    background-color: black;
}