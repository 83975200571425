.link {
    text-decoration: none !important;

    .img_box {
        color: rgb(183, 183, 183);
    }
}

.link:hover {
    .text h2 {
        color: #bcefd9;
        text-decoration: underline;
    }

    .text img {
        filter: brightness(2);
    }
}

.box {
    width: 100%;
    height: auto;
    break-inside: avoid;
    margin-bottom: 2rem;
    border-radius: 20px;
    background-color: rgb(49, 49, 49);
    position: relative;
}

.box img {
    width: 100%;
    display: block;
    margin: auto;
    border-radius: 20px 20px 0px 0px;
}

.text {
    margin: auto;
    text-align: left;
    padding: 20px;
    padding-top: 10px;
}

.text_inner {
    display: flex;
    align-items: center;
}

.text_inner h2 {
    flex-grow: 3;
}

.text_inner img {
    width: 40px;
}

.text h2 {
    font-weight: 700;
    margin-bottom: 0;
    color: #8ed0b3;
}

.text h4 {
    margin-bottom: 0;
    color: white;
    text-decoration: none !important;
}

.text p {
    margin-top: 10px;
    margin-bottom: 0;
    color:whitesmoke;
}

.overlay {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 40px;
    background-color: rgba(81, 81, 81, 0.738); 
}

.imageWrapper {
    position: relative; 
}

.img_box:first-of-type {
    display: flex;
    align-items: flex-end;
    padding-left: 3%;
}

.img_box {
    display: flex;
    align-items: flex-end;
    padding-left: 4%;
}

.img_box h5 {
    margin-bottom: 0;
    color: white;
}

.img {
    height: 25px;
    width: 30px !important;
    padding-right: 5px;
}