body {
  background-color: black;
}

.box {
  width: 100%;
  height: auto;
  background-color: #8ed0b3;
  padding-block: 1.5rem;
  padding-inline: 10%;
  text-align: left;
  display: inline-flex;
}

.div1 {
  border-right: 2px solid black;
  padding-right: 1rem;
  white-space: nowrap;
}

.div2 {
  display: flex;
  align-items: center; 
  padding-left: 1rem;
}

@media screen and (max-width: 800px) {
  .box {
    display: block;
  }

  .div1 {
    border-right: 0;
    padding-right: 0rem;

    border-bottom: 2px solid black;
    margin-bottom: 1rem;
  }

  .div2 {
    padding-left: 0rem;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  width: 85%;
  margin: auto;
  margin-block: 2rem;
}

@media screen and (max-width: 1000px) {
  .list {
      grid-template-columns: 1fr;
  }
}


